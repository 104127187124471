import { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  StandardSearchGroup,
} from "standard";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { NavWimdow } from "../Components";
import API from "../../utils/api";
import InputSelect from "../InputSelect";
import { fDate } from "../../utils/formatTime";
import ScheduleForm from "./ScheduleForm";
import ScheduleCalendar from "./ScheduleCalendar";


export default function WorkCalender() {
  const [date, setDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
  const [schedules, setSchedules] = useState([])
  const [searchName, setSearchName] = useState();
  const [attendants, setAttendants] = useState([]);
  const [selection, setSelection] = useState()

  useEffect(() => {
    query();
    async function func() {
      const info = await API.wsAllAttendants();
      info.result.sort((a, b) => b.name.localeCompare(a.name));
      setAttendants(info.result);
    }
    func();
  }, []);

  const query = async () => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDate();
    const info = await API.wsWorkSchedules(fDate(firstDay), fDate(date));
    setSchedules(info.result)
  }

  const handleSchedule = (id, startDate, days, startAt, hours) => {
    API.wsAddSchedules(id, startDate, days, startAt, hours, query)
  }

  const handleDelete = (id, startDate, days) => {
    API.wsDeleteSchedules(id, startDate, days, query)
  }

  const searchGroup = () =>
    <StandardSearchGroup
      options={[
        {
          type: 'component', name: 'date', width: 200,
          render: () => {
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      inputProps: {
                        style: {
                          width: '130px',
                        }
                      },
                    },
                  }}
                  openTo="month"
                  views={['year', 'month']}
                  size="small"
                  label="Month"
                  value={dayjs(date)}
                  onChange={(newValue) => {
                    setDate(newValue.toDate());
                  }}
                />
              </LocalizationProvider>
            )
          }
        },
        {
          type: 'component', name: 'attendants', width: 150, render: () => {
            return (
              <InputSelect
                options={attendants.map(v => { return { label: v.name, value: v.id } })}
                label="Name"
                onChange={attendantId => {
                  if (!attendantId) {
                    setSearchName(null);
                  }
                }}
                onInputChange={setSearchName}
              />
            )
          }
        }
      ]}
      queryButtonLabel='Query'
      setVersion={() => { }}
      setQueryParams={() => { }}
    />

  return (
    <>
      {
        Boolean(selection) &&
        <ScheduleForm
          open={Boolean(selection)}
          onClose={() => setSelection(null)}
          onSubmit={handleSchedule}
          onDelete={handleDelete}
          {...selection}
        />
      }
      <NavWimdow>
        {searchGroup()}
        <ScheduleCalendar
          month={date.getMonth()}
          year={date.getFullYear()}
          attendants={attendants}
          schedules={schedules}
          onClick={(v) => setSelection(v)}
          searchName={searchName}
        />
      </NavWimdow>
    </>
  )
}