import PropTypes from 'prop-types';
import { useEffect, useRef } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { fDate } from "../../utils/formatTime";

export default function ScheduleCalendar({ month, year, attendants = [], schedules = [], onClick, searchName }) {
  const tableRef = useRef(null);
  const cellWidth = 100;
  // 月份天数
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  useEffect(() => {
    if (tableRef.current) {
      const todayColumnIndex = new Date().getDate();
      tableRef.current.scrollLeft = cellWidth * (todayColumnIndex - 2);
    }
  }, []);

  const formatDate = (date) => `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

  // 日期数组
  const dates = Array.from({ length: daysInMonth }, (_, i) => new Date(year, month, i + 1));
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date()

  const getSchedule = (id, date) => {
    const dateText = fDate(date)
    return schedules.find(v => v.id === id && dateText >= v.startDate && dateText <= v.endDate)
  }

  const cell = (key, date, content) => {
    return (
      <TableCell
        key={key}
        align='center'
        sx={{
          backgroundColor: date.getDate() === today.getDate() ? 'lightgreen' : '',
        }}
      >
        {content}
      </TableCell>)
  }

  const scheduleCell = (key, id, name, date) => {
    const schedule = getSchedule(id, date)
    const info = { id, name, date, startAt: schedule?.startAt, hours: schedule?.hours }
    return (
      <TableCell
        key={key}
        align='center'
        sx={{
          backgroundColor: date.getDate() === today.getDate() ? 'lightgreen' : '',
        }}
      >
        <Button
          size="small"
          onClick={() => onClick(info)}
          sx={{ minHeight: 25 }}
          disabled={date.getDate() < today.getDate()}
        >
          {schedule ? `${schedule.startAt}:00 + ${schedule.hours}` : ''}
        </Button>
      </TableCell>
    )
  }

  return (
    <TableContainer
      component={Paper}
      ref={tableRef}
      sx={{
        height: 'calc(100vh - 135px)',
      }}
    >
      <Table
        stickyHeader
        sx={{
          '& .MuiTableCell-root': {
            border: '1px solid rgba(224, 224, 224, 1)',
            padding: '6px',
            minWidth: cellWidth,
          },
        }}
        aria-label="月历表格"
      >
        <TableHead>
          <TableRow>
            {[
              <TableCell
                key='Schedule'
                align='center'
                sx={{
                  left: 0,
                  background: '#A5f5f5',
                  zIndex: 10,
                }}
              >
                Name
              </TableCell>,
              ...dates.map((date, index) => cell(index, date,
                <div>
                  <div>{formatDate(date)}</div>
                  <div>{weekdays[date.getDay()]}</div>
                </div>))
            ]}
          </TableRow>
        </TableHead>
        <TableBody>
          {attendants
            .sort()
            .filter(v => !searchName || v.name.toLowerCase().includes(searchName.toLowerCase()))
            .map((v) => (
              <TableRow key={v.id}>
                {[
                  <TableCell
                    key={v.id}
                    align='left'
                    sx={{
                      position: 'sticky',
                      left: 0,
                      background: '#A5f5f5',
                      zIndex: 9,
                    }}
                  >
                    <Typography>
                      {v.name}
                    </Typography>
                  </TableCell>,
                  ...dates.map((date, index) => scheduleCell(index, v.id, v.name, date))
                ]}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
ScheduleCalendar.propTypes = {
  month: PropTypes.number,
  year: PropTypes.number,
  attendants: PropTypes.array,
  schedules: PropTypes.array,
  onClick: PropTypes.func,
  searchName: PropTypes.string
};